import React, { useState }  from "react";
import styled from "styled-components";
import background from "../assets/background.png";
import image from "../assets/home.png";
import ClipLoader from "react-spinners/ClipLoader";


export default function Home() {
  const [text, setText] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false)
    const handleSubmit = async (event) => {
    event.preventDefault();
    if (!text) {
      setText("Cannot be empty");
      //this.setState({ errors: errors });
      return;
    }
      //simple form validation
        setLoading(true)
        await fetch('https://orai.onrender.com/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ text }),
        })
      
      .then((res) => res.json())
      //.then(response => console.log('Success: ', response))
      .then((data) => setResponse(data.message))
      setLoading(false)
        };
    // Send the text to the server

  return (
    <Section id="home">
      <div className="text">
      <form onSubmit={handleSubmit}>
        <label>
          <input
            type="text"
            value={text}
            onChange={(event) => setText(event.target.value)} />
        </label>
        <button type="submit">AI result</button><br />
        <span className="responsehighlights">
        {
          loading ?
          <ClipLoader
          color={'#f8f8f8'}
          loading={loading}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />:
        response
        }
        </span>
      </form>
        <p>
          <span className="highlight">
          The Oracle is an AI brain that you can ask about anything you want
          </span>
        </p>
       
      </div>
      <div className="image">
        <a href="/"><img src={image} alt="Home" /></a>
      </div>
    </Section>
  );
}


const Text = styled.input`
  background-color: #ffffff;
  padding: .78em;
  width: 47%;
  border: none;

  @media(max-width:850px){
      border-radius: .2em;
      margin: .3em 0;
`
const Section = styled.section`
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: inline-block;
  position: absolute;
  grid-template-columns: 50% 50%;
  height: 100%;
  align-items: center;
  .text {
    padding-left: 10rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  .image {
    img {
      width: 80%;
    }
  }
  .highlight {
    color: white;
    font-size: 3vw;
  }
  .responsehighlights {
    color: white;
    border-style: none;
    text-align: center;
    display: inline-block;
    width: 50%;
    margin: 20px;
  }
  h1 {
    font-size: 4.8vw;
  }
  p {
    font-size: 1.5vw;
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column-reverse;
    height: max-content;
    text-align: center;
    margin: 0 1rem;
    .image {
      img {
        width: 100%;
      }
    }
    .text {
      padding-left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      h1 {
        font-size: 8vw;
      }
      p {
        font-size: 5vw;
      }
    }
  }
`;