
import Home from "./components/Home";

export default function App() {

  return (
    <div>
      <Home />
    </div>
  )
  };